import styled from 'styled-components';

export const ParagraphWrapper = styled.div.withConfig({
  displayName: 'ParagraphWrapper',
  componentId: 'paragraph-wrapper',
})([
  `
    margin-bottom: 32px;

    > *, > p {
        font-size: inherit;
        > a {
            color: #2D91FF;
        }
    }

    > div > h1, h2, h3, h4, h5, h6 {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    > div {
        h2, h3 {
            font-weight: 530;
            line-height: 24px;
            word-wrap: break-word
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 20px;
        }

        img {
            max-width: 100%;
        }

        figure {
            margin: 0;
            &.image.image-style-side {
                float: right;
                max-width: 50%;
                margin-top: 5px;
                margin-left: 10px;
            }
        }
    }

    h4, h5 {
        scroll-margin-top: 96px;
    }

    .copy-id-h-tag {
        h4, h5 {
            a svg {
                position: absolute;
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-left: 12px;
                fill: #FFF;
                path {
                    color: #FFF;
                    fill: #FFF;
                }
            }
        }

        &:hover {
            h4, h5 {
                cursor: pointer;
            }

            a svg {
                fill: #2D91FF;
                path {
                    color: #2D91FF;
                    fill: #2D91FF;
                }
            }
        }

        .ard-tooltip {
            z-index: 1001;
            font-family: "The Sans", "TheSans C5", "sans-serif";
            font-weight: 400;
            font-size: 14px;
            background-color: #EEF0F1;
            opacity: unset;
            margin-top: 8px;
            margin-left: -16px;
            padding: 4px 12px;
            border-radius: 2px;

            .react-tooltip-arrow {
                display: none;
            }
        }
    }
`,
]);
