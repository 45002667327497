import React, { useState } from 'react';
import { ParagraphWrapper } from './style';
import sanitize from '../../../scripts/utils/sanitize';
import parse, { domToReact } from 'html-react-parser';
import Image from '../image';
import Video from '../video';
import { useRouter } from 'next/router';
import { default as CopyToClipboard } from '../../../assets/logos/copyToClipboard';
import { Tooltip } from 'react-tooltip';

const Paragraph = ({ block, documentData, pageType }) => {
  const content = sanitize(block.content).__html;
  let counter = 0;

  const options = {
    replace: ({ attribs, children, name }) => {
      if (!attribs || (name !== 'h4' && name !== 'h5')) return;

      const [showToolip, setShowToolip] = useState(false);
      const childrenParsed = domToReact(children, options);
      const base = `https://${APP_SETTINGS.appUris.slice(-1)[0]}`;
      const router = useRouter();
      const elementId = `${block.__component}-${block.id}-${counter++}`;

      const links = `${base}${router.asPath.split("#")[0]}#${elementId}`;
      const copyLink = (e) => {
        navigator.clipboard.writeText(links);
        setShowToolip(true);
        setTimeout(() => {
          setShowToolip(false);
        }, 750)
      }

      const tooltip = React.createElement(Tooltip, { className: 'ard-tooltip', id: `tt-${elementId}`, key: `tt-${elementId}`, 'openEvents': ['click'], 'openOnClick': true, 'isOpen': showToolip, });
      const linkTag = React.createElement('a', { key: `a-${elementId}`, 'data-tooltip-content': "Link kopiert", 'data-tooltip-id': `tt-${elementId}`, 'data-tooltip-place': 'top-start', 'data-tooltip-wrapper': 'span', 'data-tooltip-variant': 'light', 'data-tooltip-position-strategy': 'fixed', }, [tooltip, <CopyToClipboard />])
      return React.createElement('div', { key: elementId, className: 'copy-id-h-tag', onClick: (e) => { copyLink(e); }, }, React.createElement(name, { ...attribs, id: elementId }, [childrenParsed, linkTag]));
    },
  };

  return (
    <>
      <ParagraphWrapper id={`${block.__component}-${block.id}`} className="primary-link paragraph">
        <div>{parse(content, options)}</div>
      </ParagraphWrapper>
      {block.image?.media?.data && <Image block={block.image}></Image>}
      {block.video?.media?.data && <Video block={block.video}></Video>}
    </>
  );
};

export default Paragraph;
